import "./App.css";
import logo from "./static/logo.svg"

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <strong>Kultura i Historia</strong>
          <br /> is getting a makeover...
        </p>
        <nav>
        <div className="arrow-down"></div> 
        <ul>
          <li><a href="http://www.kulturaihistoria.umcs.lublin.pl/en/">🇺🇸</a></li>
          <li><a href="http://www.kulturaihistoria.umcs.lublin.pl/pl/">🇵🇱</a></li>
        </ul>
        <h5>Please select a language to go to old site</h5>
        </nav>
      </header>
    </div>
  );
}
export default App;
